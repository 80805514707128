/* NOTE: the order of these styles DO matter. Lower in file gets higher priority. */

.DateRangePicker_picker {
  top: 44px !important;
  transform: none;
  transform-origin: 0px 0px;
  transition: opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  border-radius: 4px;
}

 /* Will edit everything selected including everything between a range of dates */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  background: #2268ea25;
  color: black;
  border: 0px;
}

.CalendarDay__default:hover,
.CalendarDay__selected_span:hover {
  background: rgb(23, 72, 163) !important;
  color: white !important;
  border: 0px;
  border-radius: 8px;
}

/* Will edit the current day */
.CalendarDay__today {
  background: #e6e6e6;
  font-weight: bolder;
  color: black;
  border: 2px solid #2267ea;
}

.CalendarDay__default,
.CalendarDay__blocked_out_of_range {
  border: 0px;
}

.CalendarDay__blocked_out_of_range:hover {
  background: white !important;
  color: #cacccd !important;
}

 /* Will edit selected date or the endpoints of a range of dates */
 /* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected,
.CalendarDay__selected:hover {
  background: #2267ea;
  color: white;
  border: 0px;
}

.CalendarDay__selected_start,
.CalendarDay__selected_start:hover {
  border-radius: 8px 0px 0px 8px;
}

.CalendarDay__selected_end,
.CalendarDay__selected_end:hover {
  border-radius: 0px 8px 8px 0px;
}

.CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 8px;
}

.CalendarDay__default:hover {
  background: #2268ea25;
  color: black;
  border: 0px;
}

.CalendarMonth_caption {
  padding-top: 4px;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 0px;
}

.DayPicker_weekHeader {
  top: 44px
}

.DateRangePickerInput {
  height: 36px;
  border-radius: 4px;
  padding: 5px 15px;
  background: #fff
}

.DateRangePickerInput_calendarIcon {
  margin-left: -4px;
  margin-right: 8px;
  padding: 0px 0px 0px 0px;
  height: 20px;
}

.DateInput__small {
  width: 80px;
}

.DateInput_input__small {
  padding: 0px 0px 0px 0px;
  text-align: center;
  font-size: 14px;
}

.DateInput_input__focused {
  border-bottom: 2px solid #2267ea;
}

.DateInput_fang {
  display: none;
}

.DateRangePickerInput_arrow {
  margin: 0px 8px;
}

.DayPickerNavigation_button__default:hover {
  background: #2267ea;
}

.DayPickerNavigation_button__default:hover .DayPickerNavigation_svg__horizontal {
  fill: white;
}